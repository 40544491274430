import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from "@angular/material";
import { exportType } from 'src/app/models/export-type.model';
import { ExportService } from 'src/app/services/export.service';
import { FileService } from 'src/app/services/file.service';
import { ReportsService } from "src/app/services/reports.service";
import { SigmentsService } from 'src/app/services/sigments.service';
import { environment } from 'src/environments/environment';
import { AddEmaillSnackBarComponent } from '../add-emaill-snack-bar/add-emaill-snack-bar.component';
import { SendEmailComponent } from "../send-email/send-email.component";
@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit {
  displayedColumns = [];
  displayedColumnsSearch = [];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  data: any;
  lastFileName: any;
  fileName: any;
  isLoading: boolean;
  reportList: any[];
  currentReport: string;
  //filterEntity: any = {};
  widthOfReportTable = "100%"
  columnToAddList = [];
  disabledSendMail = false;
  isDisplaySearch=false;
  filterEntity = {
    date: null,
    name: '',
    status: ''
  };
  constructor( private dialog: MatDialog,private reportsService: ReportsService, private _snackBar: MatSnackBar, private exportService: ExportService, private fileService: FileService) {
  }
  deleteColumn(colName) {
    let index = this.displayedColumns.indexOf(colName)
    this.columnToAddList.push({ "colName": colName, "index": index });
    this.displayedColumns.splice(index, 1);
    this.displayedColumnsSearch.splice(index, 1);
    this.widthOfReportTable = `${this.displayedColumns.length * 141}px`;
  }

  ngOnInit() {


    this.reportsService.getReports().subscribe((data) => {
      this.reportList = data
    });
  if (this.dataSource) {
      this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
        const filterObj = JSON.parse(filter);
      
        let isMatch = true;
      
        for (const col in filterObj) {
          if (filterObj[col]) {
            // בדיקה אם מדובר בתאריך
            if (this.getColumnType(col) === 'date') {
              const filterDateRange = filterObj[col];
              const dataDate = new Date(data[col]);
      
              if (filterDateRange && filterDateRange.start && filterDateRange.end) {
                const startDate = new Date(filterDateRange.start);
                const endDate = new Date(filterDateRange.end);
      
                if (dataDate < startDate || dataDate > endDate) {
                  isMatch = false;
                }
              }
            } else {
              // נוודא שהערך הוא מחרוזת לפני שמנסים להפעיל עליו trim()
              const filterValue = filterObj[col];
      
              if (typeof filterValue === 'string') {
                if (data[col] && !data[col].toString().toLowerCase().trim().includes(filterValue.toLowerCase().trim())) {
                  isMatch = false;
                }
              } else if (data[col] !== filterValue) {
                // עבור ערכים שהם לא מחרוזות, נשווה ישירות
                isMatch = false;
              }
            }
          }
        }
      
        return isMatch;
      };
      this.onSearchClear();

   }

  }
  isLinkIDColumn(disCol) {
    if (disCol.match(/LINKID.*/)) {
      return true;
    }
    return false;
  }
  openImg(imgURL) {
    const blob: Blob = this.b64toBlob(imgURL);
    const objectUrl: string = URL.createObjectURL(blob);
    window.open(objectUrl);
  }
  b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
  }
  getReport(excelMenuId: string) {
    this.onSearchClear();
    this.isLoading = true;
    let excelMenu = this.reportList.find(r => r.ExcelMemuID == excelMenuId);
    this.reportsService.getReport(excelMenu).subscribe((data) => {
      if (data != null) {

        let displayedColumns = [];
        if (data.length > 0) {
          Object.keys(data[0]).forEach(key => {
            displayedColumns.push(key);
          });
          this.widthOfReportTable = `${displayedColumns.length * 141}px`;
        }
        else {
          this.widthOfReportTable = "100%";
        }
        this.dataSource = new MatTableDataSource(data);
        this.displayedColumns = displayedColumns;
        this.displayedColumnsSearch=this.displayedColumns.map(c=>c+'Search');
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.applySearch.bind(this);
      }
      this.isLoading = false;
    });
  }
  search(value) {
    this.dataSource.filter = JSON.stringify(this.filterEntity);

  }
  displaySearch() {
    this.isDisplaySearch = !this.isDisplaySearch;
    if (this.isDisplaySearch === false) {
      this.onSearchClear();
    }
  }
  onDateChange(newDate) {
    if (!newDate || (!newDate.begin && !newDate.end)) {
      this.filterEntity['disCol'] = null;
    } else {
      this.filterEntity['disCol'] = newDate;
    }
      this.search(newDate);
  }
  
  onSearchClear() {
    this.filterEntity = { date: null,
    name: '',
    status: ''};
  }

  getColumnType(column: string) {
    const lowerCaseColumn = column.toLowerCase();
    if (lowerCaseColumn.includes('year open date') || lowerCaseColumn.includes('month open date')) {
      return 'default'; 
  }
    if (lowerCaseColumn.includes('date') || lowerCaseColumn.includes('etd') || lowerCaseColumn.includes('eta')|| lowerCaseColumn.includes('תאריך')  
        || lowerCaseColumn.includes('יצא מהארץ') || lowerCaseColumn.includes('קליטה בממן') || lowerCaseColumn.includes('הזמנת טיסה') ||lowerCaseColumn.includes('פתיחת תיק'))
    {
      return 'date';
    }
    return 'default';
  }

  // applySearch(data: any, filter: string): boolean {
  //   const filterEntity = JSON.parse(filter);
  //   let result = true;
  
  //   // Iterate over each key in the filterEntity
  //   for (const key in filterEntity) {
  //     if (filterEntity.hasOwnProperty(key)) {
  //       const filterValue = filterEntity[key];
  //       const dataValue = data[key];
  
  //       console.log('Filter Key:', key, 'Filter Value:', filterValue);
  //       console.log('Data Value for Key:', key, 'Data Value:', dataValue);
  
  //       // דילוג על שדות ריקים או לא מוגדרים
  //       if (filterValue === undefined || filterValue === null || filterValue === '') {
  //         continue;
  //       }
  
  //       // סינון לפי מחרוזות (טקסט)
  //       if (typeof filterValue === 'string') {
  //         const dataValueStr = dataValue ? dataValue.toString().trim().toLowerCase() : '';
  //         if (!dataValueStr.includes(filterValue.trim().toLowerCase())) {
  //           result = false;
  //           break;
  //         }
  //       }
  
  //       // סינון לפי טווח תאריכים
  //       else if (typeof filterValue === 'object' && filterValue.begin && filterValue.end) {
  //         // בדיקת קיום ערך בשדה הנתונים (dataValue) של התאריך
  //         if (!dataValue) {
  //           console.log(`Data value for ${key} is empty, skipping filter.`);
  //           continue;  // לא נסנן לפי שדה זה אם אין ערך בשדה הנתונים
  //         }
  
  //         const dataDate = new Date(dataValue);
  //         const beginDate = new Date(filterValue.begin);
  //         const endDate = new Date(filterValue.end);
  
  //         console.log('Data date:', dataDate, 'Begin date:', beginDate, 'End date:', endDate);
  
  //         // סינון על פי טווח תאריכים
  //         if (dataDate < beginDate || dataDate > endDate) {
  //           result = false;
  //           break;
  //         }
  //       }
  
  //       // סינון לפי מספרים
  //       else if (typeof filterValue === 'number') {
  //         if (dataValue !== filterValue) {
  //           result = false;
  //           break;
  //         }
  //       }
  //     }
  //   }
  
  //   console.log('Final result for this row:', result);
  //   return result; // מחזירים את התוצאה, אם היא מתאימה
  // }
  
  applySearch(data: any, filter: string): boolean {
    const filterEntity = JSON.parse(filter);
    let result = true;

    // Iterate over each key in the filterEntity
    for (const key in filterEntity) {
        if (filterEntity.hasOwnProperty(key)) {
            const filterValue = filterEntity[key];
            const dataValue = data[key];

            console.log('Filter Key:', key, 'Filter Value:', filterValue);
            console.log('Data Value for Key:', key, 'Data Value:', dataValue);

            // דילוג על שדות ריקים או לא מוגדרים
            if (filterValue === undefined || filterValue === null) {
                continue;
            }

            // סינון לפי מחרוזות (טקסט)
            if (typeof filterValue === 'string') {
                const dataValueStr = dataValue ? dataValue.toString().trim().toLowerCase() : '';
                if (!dataValueStr.includes(filterValue.trim().toLowerCase())) {
                    result = false;
                    break;
                }
            }

            // סינון לפי טווח תאריכים
            else if (typeof filterValue === 'object' && filterValue.begin && filterValue.end) {
                // בדיקת קיום ערך בשדה הנתונים (dataValue) של התאריך
                if (dataValue === "") {
                    console.log(`Data value for ${key} is empty, not displaying this row.`);
                    return false; // לא מציגים שורה עם תאריך ריק
                }

                const dataDate = new Date(dataValue);
                const beginDate = new Date(filterValue.begin);
                const endDate = new Date(filterValue.end);

                console.log('Data date:', dataDate, 'Begin date:', beginDate, 'End date:', endDate);

                // סינון על פי טווח תאריכים
                if (dataDate < beginDate || dataDate > endDate) {
                    result = false;
                    break;
                }
            }

            // סינון לפי מספרים
            else if (typeof filterValue === 'number') {
                if (dataValue !== filterValue) {
                    result = false;
                    break;
                }
            }
        }
    }

    console.log('Final result for this row:', result);
    return result; // מחזירים את התוצאה, אם היא מתאימה
}

  
  
  getReportExcelMenu() {
    return this.reportList.find(r => r.ExcelMemuID == this.currentReport);
  }
  openINVOICE(invoice) {
    this.fileService.downloadFile(invoice).then((data) => {
      if (data == null) {
        this._snackBar.open("הודעה", "לא נמצא קובץ", {
          duration: 2000,
        });
      } else {
        var file = new Blob([data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      }
    });

  }
  export() {
    let filteredColumn = this.filterColumn();
    this.exportService.exportAsExcelFile(filteredColumn, this.getReportExcelMenu().Description);
  }

  filterColumn() {
    if (this.dataSource.filteredData.length > 0) {
      this.dataSource.filteredData.forEach(row => {
        Object.keys(row).forEach(key => {
          if (!this.displayedColumns.includes(key)) {
            delete row[key];
          }
        })
      });
    }
    return this.dataSource.filteredData;
  }
  getWidth() {
    return "8000px"
  }
  openDialog(component, data, width, height) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data
    dialogConfig.autoFocus = true;
    dialogConfig.width = width;
    dialogConfig.height = height;
    dialogConfig.position = {
    };
    {
      dialogConfig.disableClose = true;
    }
    return this.dialog.open(component, dialogConfig);
  }
  sendMail() {
    this.openDialog(SendEmailComponent, this.getReportExcelMenu().Description, "50%", "50%").afterClosed().subscribe(data => {
      this.disabledSendMail = true;
      if (data != undefined && data.to.length > 0) {
        let filteredColumn = this.filterColumn();
        let excelMenu = this.getReportExcelMenu();
        let sendReportMail = {
          viewName: excelMenu.Description,
          reportName: excelMenu.ReportName,
          exportType: excelMenu.Type == exportType.csv ? exportType.csv : exportType.none,
          fileData: filteredColumn,
          to: data.to,
        }
        this.reportsService.sendMail(sendReportMail).subscribe(result => {
          this.disabledSendMail = false;
          this._snackBar.open("Message", "The email has been sent", {
            duration: 2000,
          });
        }, (error) => {
          this.disabledSendMail = false;
          this._snackBar.open("System error","Please contact the system administrator", {
            duration: 2000,
          });
        });
      }
      else {
        this.disabledSendMail = false;
      }
    });
    // let addEmailAddressPopUp = this._snackBar.openFromComponent(AddEmaillSnackBarComponent, {
    //   data: `הוסף כתובות`,
    // });
    // addEmailAddressPopUp.onAction().subscribe(() => {
    //   this.disabledSendMail = true;
    //   if (addEmailAddressPopUp.instance.addressList != undefined && addEmailAddressPopUp.instance.addressList.length > 0) {
    //     let filteredColumn = this.filterColumn();
    //     let excelMenu = this.getReportExcelMenu();
    //     let sendReportMail = {
    //       viewName: excelMenu.Description,
    //       reportName: excelMenu.ReportName,
    //       exportType: excelMenu.Type == exportType.csv ? exportType.csv : exportType.none,
    //       fileData: filteredColumn,
    //       to: addEmailAddressPopUp.instance.addressList,
    //     }
    //     this.reportsService.sendMail(sendReportMail).subscribe(result => {
    //       this.disabledSendMail = false;
    //       this._snackBar.open("הודעה", "המייל נשלח", {
    //         duration: 2000,
    //       });
    //     }, (error) => {
    //       this.disabledSendMail = false;
    //       this._snackBar.open("שגיאת מערכת", error.message, {
    //         duration: 2000,
    //       });
    //     });
    //   }
    //   else {
    //     this.disabledSendMail = false;
    //   }
    // });
    // addEmailAddressPopUp.afterDismissed().subscribe(() => {
    //   this.disabledSendMail = false;
    // });
  }
}
